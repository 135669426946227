import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { AlertModal, AvatarEmployee, devices, SliderContainer } from '@/UI';
import { useIframeRouter } from '@/hooks/useIframeRouter';
import { useShowComponent } from '@/hooks/showComponent';

import * as St from './SliderAvatarEmployees.styles';
import { useServiceProviders } from '@/services/serviceProviders';
import SliderAvatarEmployeesSkeleton from '../SliderAvatarEmployeesSkeleton';

export const SliderAvatarEmployees: FC<{ hasSelectedServices: boolean }> = ({ hasSelectedServices }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { formatUrl, routerPush } = useIframeRouter();
  const {
    countryCode,
    locationId,
    providerId,
    token,
    creative_source: creativeSource,
  } = router.query as {
    countryCode: string;
    locationId: string;
    providerId: string;
    token: string | null;
    // eslint-disable-next-line camelcase
    creative_source?: string;
  };
  const { data: providersByLocation, isLoading } = useServiceProviders(locationId);
  const [selectedId, setSelectedId] = useState<number>();
  const { show, hide, isShown } = useShowComponent();
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  let queryParam = '';

  if (token && creativeSource) {
    queryParam = `?token=${token}&creative_source=${creativeSource}`;
  } else if (token) {
    queryParam = `?token=${token}`;
  } else if (creativeSource) {
    queryParam = `?creative_source=${creativeSource}`;
  }

  const handleRouting = (provider) => {
    if (hasSelectedServices) {
      setSelectedId(provider.id);
      return show();
    }
    return routerPush(`/${countryCode}/sucursal/${locationId}/profesional/${provider.id}${queryParam}`);
  };

  const ListAvatarEmployees = () =>
    providersByLocation
      ?.filter((p) => p.onlineBooking && p.services.length)
      .sort((a, b) => a.order - b.order)
      .map((p) => {
        if (hasSelectedServices) {
          return (
            <St.ProviderButton
              key={p.id}
              data-testid="providerLink"
              onClick={() => handleRouting(p)}
              data-cy="provider_button"
            >
              <AvatarEmployee name={p.publicName} image={p.image} />
            </St.ProviderButton>
          );
        }
        return (
          <Link
            key={p.id}
            href={formatUrl(`/${countryCode}/sucursal/${locationId}/profesional/${p.id}${queryParam}`)}
            passHref
          >
            <St.NavLink data-testid="providerLink" data-cy="provider_link">
              <AvatarEmployee name={p.publicName} image={p.image} />
            </St.NavLink>
          </Link>
        );
      });

  if (providerId) {
    return null;
  }

  if (isLoading) {
    return <SliderAvatarEmployeesSkeleton />;
  }

  return (
    <St.Wrapper>
      {!!providersByLocation && (
        <>
          <St.Label size="paragraph" weight="bold">
            {t('SLIDER_AVATAR_EMPLOYEES.TITLE')}
          </St.Label>
          {isXLarge ? (
            <SliderContainer data-testid="sliderContainer">{ListAvatarEmployees()}</SliderContainer>
          ) : (
            <St.SliderAvatarMobile>{ListAvatarEmployees()}</St.SliderAvatarMobile>
          )}
        </>
      )}
      {isShown && (
        <AlertModal
          className="alert"
          confirmButtonText={t('SLIDER_AVATAR_EMPLOYEES.ALERT_BUTTON')}
          title={t('SLIDER_AVATAR_EMPLOYEES.ALERT_TITLE')}
          description={t('SLIDER_AVATAR_EMPLOYEES.ALERT_DESCRIPTION')}
          showCancelButton
          onCancel={hide}
          onConfirm={() =>
            routerPush(
              `/${countryCode}/sucursal/${locationId}/profesional/${selectedId}/${
                creativeSource ? `?creative_source=${creativeSource}` : ''
              }`,
            )
          }
        />
      )}
    </St.Wrapper>
  );
};
