import styled from 'styled-components';
import { devices } from '../../Styles';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled.div<{ amountOfLines: number; clamped: boolean }>`
  color: ${({ theme }) => theme.palette.grays80};
  .clamp {
    -webkit-line-clamp: ${({ amountOfLines, clamped }) => (clamped ? amountOfLines : '')};
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
  p {
    white-space: pre-line;
  }
  @media ${devices.XLarge} {
    padding: ${({ theme }) => theme.spacing[12]};
  }
`;

export const DetailLabel = styled(Paragraph)`
  display: none;

  @media ${devices.Large} {
    display: block;
    margin-bottom: 22px;
  }
`;

export const DetailContainer = styled.div`
  position: relative;
  text-align: justify;
`;

export const ShowDetailButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
  color: ${({ theme }) => theme.brandTheme.brand100};
`;

export const ShowDetailIconContainer = styled.button`
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  p {
    margin-left: 4px;
  }
`;

export const ViewMoreLabel = styled(Paragraph)`
  text-decoration: underline;
`;

export const TextContentSecondary = styled.span`
  display: block;
  margin-top: 1rem;
`;

export const DescriptionContainer = styled.div``;
