import { FC } from 'react';
import { BannerLogoProps } from './BannerLogo.types';
import * as St from './BannerLogo.styles';

export const BannerLogo: FC<BannerLogoProps> = ({ banner, logo, collapsed }) => (
  <St.Wrapper collapsed={collapsed}>
    {!collapsed && banner ? (
      <St.NextImage data-testid="banner" src={banner} layout="fill" alt="company banner" />
    ) : (
      <St.BannerGradient />
    )}
    <St.Logo src={logo} alt="logo" data-testid="logo" collapsed={collapsed} />
  </St.Wrapper>
);
