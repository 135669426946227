/* eslint-disable no-console */
import { useTheme } from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { CirclePrice, CreditCard, Information, Percentaje } from '@agendapro/emerald-icons';
import { Text, Notification } from '@agendapro/emerald/v2';
import { useMediaQuery } from 'react-responsive';
import { useReservation } from '@/context/ReservationsContext';
import { ServiceCollapse } from './ServiceCollapse';
import { Paragraph, devices } from '@/UI';
import { ServiceReservationProps } from '@/context/ReservationsContext/ReservationContext.types';
import useFormatCurrency from '@/hooks/useFormatCurrency';
import usePageContext from '@/hooks/usePageContext';
import * as St from './ReservationsSummary.styles';
import { Separator } from './ServiceCollapse/ServiceCollapse.styles';
import { Accordion } from '../Accordion';

export const ReservationsSummary: FC = () => {
  const { t } = useTranslation();
  const {
    migrateColors,
    tokens: { color },
  } = useTheme();
  const { isSalfa, companyOverview } = usePageContext();
  const { reservations } = useReservation();
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const { servicesToReserve, servicesWithProviders, bookingMode, companyBookingInfo } = reservations;
  const fc = useFormatCurrency();
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  const companyAllowsOnlinePayment =
    companyOverview?.allowsOnlinePayment &&
    companyOverview?.onlinePaymentCapable &&
    companyOverview?.providerOnlinePayment;

  const mustBePaidOnline = servicesToReserve.some((service) => service.mustBePaidOnline);
  const isNotOnlinePayable = servicesToReserve.filter((service) => !service.onlinePayable).length;
  const isOnlinePayable = servicesToReserve.some((service) => service.onlinePayable);
  const hasOnlineDownpayment = servicesToReserve.some(
    (service) => service.mustBePaidOnline && service.onlineDownPayment,
  );
  const onlineDiscount = servicesToReserve
    .map((service) => {
      if ((service.mustBePaidOnline || service.onlinePayable) && service.discount && !service.charlyDiscount) {
        return +service.price - +service.payingPrice;
      }
      return 0;
    })
    .reduce((a, b) => +a + +b, 0);

  const isBundle = servicesToReserve.some((service) => service.bundled);
  const allServicesFree = servicesToReserve.every((service) => service.price === '' && !!service.showPrice);

  const getTotal = (services: ServiceReservationProps[]) => {
    if (services[0].hasSession) {
      if (services[0].showPrice) {
        return { total: fc(services[0].price), online: fc(services[0].payingPrice) };
      }
      return {
        total: t('RESERVATION_SUMMARY.TO_DETERMINE'),
        online: '',
      };
    }
    const { total, online } = services
      .map((service) => {
        const isPromoActive = dayjs(service.daySelected).isBetween(
          service.charlyPromotionStartDate,
          service.charlyPromotionEndDate,
          'day',
          '[]',
        );

        if (!!service.charlyDiscount && isPromoActive) {
          return {
            total: service.payingPriceCharly || 0,
            online: service.mustBePaidOnline || service.onlinePayable ? service.payingPriceCharly || 0 : 0,
          };
        }
        let onlineTotal = 0;

        if (service.mustBePaidOnline || service.onlinePayable) {
          if (service.onlineDownPayment) {
            onlineTotal = service.onlineDownPaymentAmount || 0;
          } else {
            onlineTotal = +service.payingPrice || 0;
          }
        }
        return { total: +service.price, online: onlineTotal };
      })
      .reduce(
        (acc, curr) => ({
          total: acc.total + curr.total,
          online: acc.online + curr.online,
        }),
        { total: 0, online: 0 },
      );

    if (servicesToReserve.some((service) => !service.showPrice)) {
      return {
        total: t('RESERVATION_SUMMARY.TO_DETERMINE'),
        online: fc(online),
      };
    }
    return {
      total: fc(total),
      online: fc(online),
    };
  };

  const { total, online } = getTotal(servicesToReserve);

  const getConsecutiveServices = (services: ServiceReservationProps[], isBundle: boolean): any => {
    if (isBundle) {
      return [
        {
          serviceProvider: { id: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') },
          name: services.map((service) => service.name).join(', '),
          price: services
            .map((service) => (typeof service.price === 'string' ? 0 : service.price))
            .reduce((a, b) => a + b, 0),
          payingPrice: services
            .map((service) => (typeof service.payingPrice === 'string' ? 0 : service.payingPrice))
            .reduce((a, b) => a + b, 0),
          duration: services.map((service) => service.duration).reduce((a, b) => a + b, 0),
          dateFormatted: services[0].dateFormatted,
          hourSelected: services[0].hourSelected,
          hourEnd: services[0].hourEnd,
          mustBePaidOnline,
          isVideo: services.some((service) => service.isVideo),
          hasSession: services.some((service) => service.hasSession),
          id: services[0].id,
        },
      ];
    }
    return services.map((service) => ({
      ...service,
      serviceProvider: servicesWithProviders[service.internalId || ''],
      dateFormatted: services[0].dateFormatted,
      hourSelected: services[0].hourSelected,
      hourEnd: services[0].hourEnd,
    }));
  };

  useEffect(() => {
    if (isXLarge || services?.length === 1 || companyBookingInfo.clientExclusive) {
      setAccordionOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const services =
    isBundle || bookingMode === 'consecutive' ? getConsecutiveServices(servicesToReserve, isBundle) : servicesToReserve;

  const priceSection = (
    <div id="price-section" className="flex flex-col gap-1">
      {(!!total || !!online || allServicesFree) && (
        <>
          {(total || allServicesFree) && (
            <St.Row>
              <CirclePrice size={25} />
              <Text type="body" data-testid="service-total">
                {' '}
                {allServicesFree ? '$0' : total}
              </Text>
            </St.Row>
          )}
          {companyAllowsOnlinePayment && isOnlinePayable && !!online && (
            <St.Row>
              <CreditCard size={25} />
              <Text type="body" className="font-medium">
                {online}
              </Text>
              <Text type="body" className="font-medium" data-testid="reservations_summary_total_online">
                {t('RESERVATION_SUMMARY.TOTAL_ONLINE')}
              </Text>
            </St.Row>
          )}
          {companyAllowsOnlinePayment && onlineDiscount > 0 && !isBundle && (
            <St.OnlineDiscountOffer isOpen={accordionOpen}>
              <Percentaje size={16} />
              <Text type="body">{`${t('RESERVATION_SUMMARY.DISCOUNT_SAVING', {
                amount: fc(onlineDiscount),
              })}`}</Text>
            </St.OnlineDiscountOffer>
          )}
        </>
      )}
    </div>
  );

  const AccordionTitle = (
    <>
      <St.AccordionButton data-testid="services_list_accordion">
        {services.length > 1 ? (
          <Text type="body-2">{services.length} servicios seleccionados</Text>
        ) : (
          <Text type="body-2">{services[0].name}</Text>
        )}
      </St.AccordionButton>

      {!accordionOpen && <>{priceSection}</>}
    </>
  );

  return (
    <div className="w-full flex flex-col gap-1">
      <Accordion
        isOpen={accordionOpen}
        title={AccordionTitle}
        setIsOpen={() => setAccordionOpen(!accordionOpen)}
        margin="8px"
        className="m-0 mb-2"
      >
        <St.Wrapper data-testid="accordion-content">
          <St.ReservationSummaryContainer>
            <div>
              <ServiceCollapse services={services} />
              <Separator />
              {priceSection}
            </div>
          </St.ReservationSummaryContainer>
        </St.Wrapper>
      </Accordion>
      {!isSalfa && (
        <>
          {companyAllowsOnlinePayment &&
            isNotOnlinePayable > 0 &&
            isNotOnlinePayable !== servicesToReserve.length &&
            !isBundle && (
              <div className="mb-2">
                <div className="flex items-center justify-between w-full p-4 gap-1 rounded-sm border-solid border border-border-info bg-background-infoSoft lg:justify-evenly">
                  <Information size={25} color={color.icon.info} />
                  <Text
                    color={color.text.info}
                    className="w-11/12 md:w-full"
                    type="body"
                    data-testid="reservations_summary_must_pay_later"
                  >{`${t('RESERVATION_SUMMARY.REMINDER_MUST_PAY_LATER')}`}</Text>
                </div>
              </div>
            )}
          {companyAllowsOnlinePayment && hasOnlineDownpayment && !isBundle && (
            <div className="mb-2">
              <div className="flex items-center justify-between w-full p-4 gap-1 rounded-sm border-solid border border-border-info bg-background-infoSoft lg:justify-evenly">
                <Information size={25} color={color.icon.info} />
                <Text
                  color={color.text.info}
                  className="w-11/12 md:w-full"
                  type="body"
                  data-testid="reservations_summary_must_pay"
                >{`${t('RESERVATION_SUMMARY.REMINDER_MUST_PAY')}`}</Text>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
