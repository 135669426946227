import styled, { css } from 'styled-components';
import { devices } from '../../Styles';

interface IconContainerProps {
  size: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'xMedium' | 'large' | number;
}

export const IconContainer = styled.i<IconContainerProps>`
  align-items: center;
  display: flex;
  font-size: ${({ size, theme }) => theme.iconSize[`${size}-mobile`]};
  justify-content: center;

  ${({ size, theme }) =>
    typeof size === 'string'
      ? css`
          svg {
            width: ${theme.iconSize[`${size}-mobile`]};
            height: ${theme.iconSize[`${size}-mobile`]};

            @media ${devices['no-mobile']} {
              width: ${theme.iconSize[`${size}-desktop`]};
              height: ${theme.iconSize[`${size}-desktop`]};
              font-size: ${theme.iconSize[`${size}-desktop`]};
            }
          }
        `
      : css`
          svg {
            width: ${size};
            height: ${size};
          }
        `}
`;
