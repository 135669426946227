import styled from 'styled-components';

export const SocialIconsContainer = styled.div`
  display: flex;

  a:not(:first-child) {
    margin-left: 12px;
  }
  .socialIcon {
    cursor: pointer;
    padding: 7px;
    path {
      fill: ${({ theme }) => theme.palette.white};
    }
    border-radius: 50%;
    background-color: ${({ theme }) => theme.brandTheme.brand100};
  }
`;
