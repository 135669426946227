import { API_V1, API_V2 } from '@/infra/http';

export const pagoFacilPayment = (totalPrice, customerEmail, companyId) =>
  API_V2.get(
    `/easy_payments/get_pago_facil_url_callback?url_callback=https://agendapro.ngrok.io/api/views/workflow/v1/easy_payments/pago_facil_detail`,
    { params: { companyId, customerEmail, totalPrice } },
  ).then((res) => res.data);

export const initDLocalTransaction = (priceTotal, customerEmail, companyId) =>
  API_V1.get(`/api_views/workflow/v1/payments/dlocal/init_transaction`, {
    params: {
      priceTotal,
      customerEmail,
      companyId,
    },
  }).then((res) => res.data);

export const processDLocalTransaction = (cardToken: string, tokenData: string, deviceId: string, fullName: string) =>
  API_V1.get(`/api/views/workflow/v1/payments/dlocal/process_transaction`, {
    params: { token: cardToken, tokenData, deviceId, fullName },
  }).then((res) => res.data);

export const checkExistingClient = (clientId: string, companySlug: string, identificadorType: number) =>
  API_V2.get(`/check_client_company`, {
    params: {
      identificador: clientId,
      website: companySlug,
      identificadorType,
    },
  }).then((res) => res.data);

export const decryptTokenData = (tokenData) =>
  API_V2.post(`/dlocal/decrypt_token`, {
    tokenData,
  }).then((res) => res.data);
