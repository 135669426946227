import { FC } from 'react';
import { Close } from '@agendapro/emerald-icons';

import { Icon, Paragraph } from '../../Atoms';
import { useLockBodyScroll } from '@/hooks';

import * as St from './TitledModal.styles';
import { TitledModalProps } from './TitledModal.types';

export const TitledModal: FC<TitledModalProps> = ({ title, children, handleClose, className }) => {
  useLockBodyScroll();
  return (
    <St.Wrapper className={className}>
      <St.Card>
        <St.Header>
          <Paragraph weight="bold" size="sectionHeadline">
            {title}
          </Paragraph>
          <Icon icon={<Close />} className="closeIcon" onClick={() => handleClose()} />
        </St.Header>
        <St.Content>{children}</St.Content>
      </St.Card>
    </St.Wrapper>
  );
};
