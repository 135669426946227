import { FC, useState, useRef, useLayoutEffect, RefObject } from 'react';
import { View, ViewLocked } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import debounce from 'lodash/debounce';
import { Icon, Paragraph } from '../..';
import { ViewMoreProps } from './types';
import ViewMoreSkeleton from './ViewMoreSkeleton';
import * as St from './ViewMore.styles';

export const ViewMore: FC<ViewMoreProps> = ({
  resourceName,
  textContent,
  textContentSecondary,
  showIcon,
  maxNumOfLines = 2,
  isLoading,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { brandTheme } = useTheme();
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const handleClick = () => setClamped(!clamped);

  useLayoutEffect(() => {
    const hasClamping = (ref: RefObject<HTMLDivElement>) => ref.current?.clientHeight !== ref.current?.scrollHeight;
    const checkButtonAvailability = () => setShowButton(hasClamping(containerRef));
    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef, isLoading]);

  return (
    <St.Wrapper {...props} amountOfLines={maxNumOfLines} clamped={clamped}>
      {isLoading ? (
        <ViewMoreSkeleton />
      ) : (
        <>
          {resourceName! && (
            <St.DetailLabel size="subHeadline" weight="bold">
              {`${t('ABOUT')} ${resourceName}`}
            </St.DetailLabel>
          )}

          <St.DetailContainer>
            {textContent && (
              <St.DescriptionContainer ref={containerRef} className="clamp">
                <Paragraph weight="light">{textContent}</Paragraph>
              </St.DescriptionContainer>
            )}
            {textContentSecondary && (
              <Paragraph weight="light" className={clamped ? 'clamp' : undefined}>
                <St.TextContentSecondary>{textContentSecondary}</St.TextContentSecondary>
              </Paragraph>
            )}
            {showButton && !showIcon && (textContent || textContentSecondary) && (
              <St.ShowDetailButton type="button" onClick={handleClick}>
                <Paragraph className="viewMoreLabel" color={brandTheme.brand100}>
                  {!clamped ? `${t('READ_LESS')}` : `${t('READ_MORE')}`}
                </Paragraph>
              </St.ShowDetailButton>
            )}
            {showButton && showIcon && (textContent || textContentSecondary) && (
              <St.ShowDetailIconContainer type="button" onClick={handleClick}>
                <Icon
                  icon={!clamped ? <ViewLocked color={brandTheme.brand100} /> : <View color={brandTheme.brand100} />}
                />
                <Paragraph weight="light" color={brandTheme.brand100}>
                  {!clamped ? `${t('SEE_LESS')}` : `${t('SEE_MORE')}`}
                </Paragraph>
              </St.ShowDetailIconContainer>
            )}
          </St.DetailContainer>
        </>
      )}
    </St.Wrapper>
  );
};
