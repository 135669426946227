import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const Wrapper = styled.div`
  margin: auto;
  padding: 0;
  position: relative;
  width: 100%;

  @media ${devices['no-mobile']} {
    max-width: 540px;
  }
  @media ${devices.Medium} {
    max-width: 720px;
  }
  @media ${devices.Large} {
    max-width: 960px;
  }
  @media ${devices.XLarge} {
    max-width: 1140px;
  }
`;
