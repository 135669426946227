/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NextSeo } from 'next-seo';

import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { AppFooter, Theme } from '@/UI';
import { brandTheme } from '@/UI/Theme/brandTheme';
import { NavBar } from '@/components';
import { InactiveNotice } from '@/components/InactiveNotice';
import useIsIframe from '@/hooks/useIsIframe';
import { PageContext } from './PageContext';
import { PageProviderProps } from './types';
import { DialogsContextProvider } from '@/context/DialogsContext';
import * as St from './PageContext.styles';
import { getBrandColors, getRGBColor } from '@/utils';
import { useLocation } from '@/services/locations';
import { Gtag } from '@/lib/gtag';
import { FbPixel } from '@/lib/fpixel';
import { ENV } from '@/config/env';
import useLazyLoadScript from '@/hooks/useLazyLoadScript';
import { mixpanelInit } from '@/lib/mixpanel';

const ActivationDialog = dynamic(() => import('@/components/ActivationDialog/ActivationDialog'));

export const PageProvider: FC<PageProviderProps> = ({
  children,
  companyOverview,
  companySlug,
  hideSelectBranch,
  locationId,
  nextSeo,
  queryParams,
  noPadding,
}) => {
  const router = useRouter();
  const [selectedServices, setSelectedServices] = useState(false);
  const { t } = useTranslation();
  const isIframe = useIsIframe();
  const { data: currentLocation, isLoading: isLoadingCurrentLocation } = useLocation(locationId as string);
  const lazyLoadScript = useLazyLoadScript();

  const isMiniSiteActive = companyOverview?.minisiteIsActive;
  const { countryCode, creative_source: creativeSource } = router.query as {
    countryCode: string;
    // eslint-disable-next-line camelcase
    creative_source?: string;
  };
  const isCancelRoute = router.pathname.includes('bookings/cancel');
  const isConfirmRoute = router.pathname.includes('bookings/confirm');
  const isConsentRoute = router.pathname.includes('sign_consents');
  const isSalfa = !!companyOverview?.customMinisite;
  const isSiteAvailable =
    !(!isMiniSiteActive || (!currentLocation.validLocation && !!router.query.locationId)) &&
    !!companyOverview?.companyIsActive;

  useEffect(() => {
    if (ENV.ENVIRONMENT === 'production') {
      const handleRouteChange = (url) => {
        Gtag.pageview(url);
        FbPixel.pageview();
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    return undefined;
  }, [router]);

  useEffect(() => {
    mixpanelInit();
  }, []);

  const brand = getRGBColor(companyOverview?.colorMinisite, 'brand');

  return (
    <PageContext.Provider
      value={{
        companyOverview,
        companySlug,
        countryCode,
        currentLocation,
        isSalfa,
        setSelectedServices,
        creativeSource,
        isSiteAvailable,
        queryParams: queryParams || '',
      }}
    >
      <Head>
        <style>:root {`{${brand}}`}</style>
        <noscript>
          <img
            alt=""
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${ENV.FB_PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
        {companyOverview.facebookCustomTracker && (
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${companyOverview.facebookCustomTracker}&ev=PageView&noscript=1`}
              alt=""
            />
          </noscript>
        )}
      </Head>
      {lazyLoadScript && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${ENV.FB_PIXEL_ID});
                ${companyOverview.facebookCustomTracker && `fbq('init', ${companyOverview.facebookCustomTracker})`}
                fbq('track', 'PageView');
              `,
          }}
        />
      )}
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {lazyLoadScript && (
        <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${ENV.GA_TRACKING_ID}`} />
      )}
      {lazyLoadScript && companyOverview?.googleCustomTracker && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${companyOverview.googleCustomTracker}`}
        />
      )}
      {lazyLoadScript && (
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ENV.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            ${
              companyOverview?.googleCustomTracker
                ? `gtag('config', '${companyOverview.googleCustomTracker}', {
                    page_path: window.location.pathname,
                    ${
                      companyOverview?.googleCustomTrackerDomain
                        ? `cookie_flags: 'SameSite=None;Secure',
                          linker: {
                            domains: ['${companyOverview.googleCustomTrackerDomain}']
                          }`
                        : ''
                    }
                  });`
                : ''
            }
          `,
          }}
        />
      )}
      <NextSeo
        title={t('SEO.BOOK_ONLINE')}
        {...nextSeo}
        openGraph={{
          images: [
            {
              url: companyOverview.logo,
              width: 300,
              height: 200,
              alt: 'logo',
              type: 'image/jpeg',
            },
          ],
        }}
      />
      {router.isReady && (
        <Theme
          brandTheme={{
            ...brandTheme,
            ...(companyOverview?.colorMinisite ? getBrandColors(companyOverview?.colorMinisite) : undefined),
          }}
        >
          {companyOverview && (
            <DialogsContextProvider>
              {!isIframe && <NavBar hideSelectBranch={hideSelectBranch} />}
              {!isSiteAvailable &&
                !isLoadingCurrentLocation &&
                !isCancelRoute &&
                !isConfirmRoute &&
                !isConsentRoute && <InactiveNotice />}
              {!companyOverview.minisiteV2Active && <ActivationDialog />}
              <St.Container isActive={isSiteAvailable || isCancelRoute || isConfirmRoute || isConsentRoute}>
                <St.AppContent
                  isActive={isSiteAvailable || isCancelRoute || isConfirmRoute || isConsentRoute}
                  noPadding={noPadding}
                >
                  {children}
                </St.AppContent>
                {!isIframe && (
                  <St.Footer selectedServices={selectedServices}>
                    <AppFooter />
                  </St.Footer>
                )}
              </St.Container>
            </DialogsContextProvider>
          )}
        </Theme>
      )}
    </PageContext.Provider>
  );
};
