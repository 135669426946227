import styled from 'styled-components';
import { applyFlexAndCenter, devices } from '../../Styles';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.brandTheme.brand100};
  align-items: center;
`;

export const ScrollbarWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-y: hidden;
  margin: 0 4px;
  column-gap: ${({ theme }) => theme.spacing[8]};
`;

export const ArrowButton = styled.button`
  ${applyFlexAndCenter}
  padding: 0;
`;
