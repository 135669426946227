export const palette = {
  text: '#333333',
  background: 'rgb(243, 243, 243)',
  backgroundSecondary: '#F9F9F9',
  border: '#C0C0C0',
  facebookBlue: '#3b5998',
  secondary: '#035C5A',

  white: 'rgb(255, 255, 255)',
  whitePearl: '#F3F3F3',
  white20: 'rgba(255, 255, 255, 0.2)',
  white70: 'rgba(255, 255, 255, 0.7)',

  alert100: 'rgb(245, 79, 63)',
  alert20: 'rgba(245, 79, 63, .20)',

  grays200: '#474747',
  grays100: '#1A1A1A',
  grays80: 'rgba(26, 26, 26, .8)',
  grays50: 'rgba(26, 26, 26, .5)',
  grays20: 'rgba(26, 26, 26, .2)',
  grays02: 'rgba(26, 26, 26, .02)',

  graysPlaceholder: '#A0A0A0',

  accent100: '#F4BA0B',
  accent80: 'rgba(255, 151, 0, .8)',
  accent50: 'rgba(255, 151, 0, .5)',
  accent20: 'rgba(255, 151, 0, .2)',

  custom100: '#CA8C9D',
  custom80: 'rgba(202, 140, 157, .8)',
  custom50: 'rgba(202, 140, 157, .5)',
  custom20: 'rgba(202, 140, 157, .2)',

  primary100: 'rgb(34, 196, 136)',
  primary80: 'rgba(34, 196, 136, .8)',
  primary50: 'rgba(34, 196, 136, .5)',
  primary25: 'rgba(34, 196, 136, .25)',
  primary20: 'rgba(34, 196, 136, .2)',

  email: '#4B75F5',

  // MAIN COLORS

  emerald50: '#D3F3E7',
  emerald100: '#90E1C3',
  emerald200: '#4ED0A0',
  emerald400: '#22c488',
  emerald500: '#188C61',
  emerald600: '#13704E',
  emerald700: '#0A3827',

  greenDark50: '#CDDEDE',
  greenDark100: '#81ADAC',
  greenDark200: '#357D7B',
  greenDark400: '#035C5A',
  greenDark500: '#034F4D',
  greenDark600: '#024240',
  greenDark700: '#011A1A',

  orange50: '#FFEACC',
  orange100: '#FFCB80',
  orange200: '#FFAC33',
  orange400: '#FF9700',
  orange500: '#B66C00',
  orange600: '#925600',
  orange700: '#492B00',

  grey50: '#D1D1D1',
  grey100: '#8C8C8C',
  grey200: '#474747',
  grey400: '#1A1A1A',

  // SECONDARY COLORS

  yellow50: '#FDF1CE',
  yellow100: '#F9DC85',
  yellow400: '#F4BA0B',
  yellow500: '#AE8508',
  yellow700: '#695005',

  babyBlue50: '#D2F0FA',
  babyBlue100: '#8ED9F2',
  babyBlue400: '#1EB4E6',
  babyBlue500: '#1581A4',
  babyBlue700: '#093342',

  red50: '#FDDCD9',
  red100: '#FAA79F',
  red400: '#F54F3F',
  red500: '#AF382D',
  red700: '#461712',

  purple50: '#F1D8F1',
  purple100: '#E4B2E4',
  purple400: '#F54F3F',
  purple500: '#8F5D8F',
  purple700: '#472E47',

  blue50: '#BECDFC',
  blue100: '#7D9CF8',
  blue400: '#3C6AF5',
  blue500: '#2847A3',
  blue700: '#142352',
};
