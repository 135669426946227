import i18next from 'i18next';
import { flatten } from 'lodash/array';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { encode } from 'js-base64';
import { BookingRequest } from '@/services/bookings';
import {
  Client,
  ReservationStateProps,
  ServiceReservationProps,
  ServicesWithProvidersProps,
} from '@/context/ReservationsContext/ReservationContext.types';
import { bundledServiceProps } from '@/services/services';

dayjs.extend(isBetween);

export const getServicesTotal = (services: ServiceReservationProps[] | bundledServiceProps[]): number =>
  services
    .map((service: ServiceReservationProps | bundledServiceProps) => +service.price)
    .reduce((acc, curr) => acc + curr, 0);

export const getBookings = (
  services: ServiceReservationProps[],
  servicesWithProviders: ServicesWithProvidersProps,
  bookingMode: string | null,
): BookingRequest[] => {
  const renameKeys = () => {
    const keyValues = Object.keys(servicesWithProviders || {}).map((key) => {
      const newKey = key.split('-')[0];

      return { [newKey]: servicesWithProviders[key] };
    });

    return Object.assign({}, ...keyValues);
  };
  const serviceWithProviderServiceId = renameKeys();

  const getProviderLock = (internalId: string) =>
    internalId in servicesWithProviders ? servicesWithProviders[internalId].providerId !== 0 : true;

  const getBundledProviderLock = (serviceId: number) => {
    if (serviceId in serviceWithProviderServiceId) {
      return serviceWithProviderServiceId[serviceId].providerId !== 0;
    }
    return true;
  };

  const getServicePayload = (service) => ({
    bundled: service.bundled,
    bundleId: service.bundleId,
    emailTrackNumber: service.emailTrackNumber,
    end: service.end,
    start: service.start,
    serviceId: service.service,
    price: service.price,
    providerId: service.providerId,
    serviceProviderId: service.providerId,
    timeResourceId: service.timeResourceId,
  });

  if (bookingMode === 'consecutive') {
    return services.map((service) => ({
      ...getServicePayload(service),
      providerLock: getBundledProviderLock(service.id),
    }));
  }

  return flatten(
    services.map((service) => {
      if (service.bundled) {
        return service.bundle.map((bundle) => ({
          ...getServicePayload(bundle),
          providerLock: getBundledProviderLock(bundle.id),
        }));
      }
      return {
        ...getServicePayload(service),
        providerLock: service.internalId ? getProviderLock(service.internalId) : false,
      };
    }),
  );
};

export const formatCustomAttributes = (client: Client) => {
  // eslint-disable-next-line camelcase
  const custom_attributes = {};

  let formattedClient: any = {};

  formattedClient = { ...formattedClient, custom_attributes };
  Object.entries(client || {}).forEach(([key, value]) => {
    if (key.substring(0, 7) === 'custom_') {
      delete Object.assign(custom_attributes, { [`${key.substring(7)}_attribute`]: value })[`custom_${key}`];
    } else if (key === 'birthDate') {
      const [birthDay, birthMonth, birthYear] = (value || '').split('-');

      formattedClient.birthDay = birthDay;
      formattedClient.birthMonth = birthMonth;
      formattedClient.birthYear = birthYear;
    } else if (key === 'secondPhone') {
      formattedClient.secondPhone = value?.length < 3 ? null : value;
    } else {
      formattedClient[`${key}`] = value;
    }
  });
  return formattedClient;
};

export const getAccessTokenAndId = (redirectUrl) => {
  const splittedRedirect = redirectUrl.split('/');
  const idAndToken = splittedRedirect[3];
  const bookingId = idAndToken?.split('?')[0];
  const accessToken = idAndToken?.split('=')[1];

  return { bookingId, accessToken };
};

export const remToPixelConverter = (remString) => {
  const rem = remString.match(/(\d+)/);
  const pixels = 16 * rem[0];

  return pixels;
};

export const formatIdCl = (value) => {
  if (!value) {
    return value;
  }
  const id = value.replace(/[^\dkK]/g, '');
  const { length } = id;

  if (length === 8) {
    return `${id.slice(0, 1)}.${id.slice(1, 4)}.${id.slice(4, 7)}-${id.slice(7, 8)}`;
  }
  if (length >= 9) {
    return `${id.slice(0, 2)}.${id.slice(2, 5)}.${id.slice(5, 8)}-${id.slice(8, 9)}`;
  }
  return id;
};

export const rutValidate = (rut) => {
  const unFormattedRut = rut.replace(/[^\dkK]/g, '');
  const body = unFormattedRut.slice(0, -1);

  if (body.length < 7) {
    return false;
  }
  if (body.length > 9) {
    return false;
  }
  let verifierDigit = unFormattedRut.slice(-1).toUpperCase();

  let sum = 0;
  let mul = 2;

  for (let i = 1; i <= body.length; i += 1) {
    const index = mul * parseInt(unFormattedRut.charAt(body.length - i), 10);

    sum += index;
    if (mul < 7) {
      mul += 1;
    } else {
      mul = 2;
    }
  }
  const expectedVerifierDigit = 11 - (sum % 11);

  verifierDigit = verifierDigit === 'K' ? 10 : verifierDigit;
  verifierDigit = parseInt(verifierDigit, 10) === 0 ? 11 : verifierDigit;
  if (expectedVerifierDigit === parseInt(verifierDigit, 10)) {
    return true;
  }
  return false;
};

export const axiosErrorHandler = (error) => {
  let errorMsg = '';

  if (error && error.response?.data) {
    if (error.response.data) {
      errorMsg = i18next.t('ERRORS.GENERIC');
    }
    if (error.response.data.error) {
      errorMsg = error.response.data.error;
    }
    if (error.response.data.errors) {
      try {
        [errorMsg] = JSON.parse(error.response.data.errors);
      } catch {
        errorMsg = error.response.data.errors;
      }
    }
  } else if (error.response) {
    errorMsg = i18next.t('ERRORS.RESPONSE');
  } else if (error.request) {
    errorMsg = i18next.t('ERRORS.REQUEST');
  } else {
    errorMsg = i18next.t('ERRORS.GENERIC');
  }
  return errorMsg;
};

export const filterEmptyBookings = (bookingObject) => {
  const available = bookingObject && Object.keys(bookingObject.available).length;
  const unavailable = bookingObject && Object.keys(bookingObject.unavailable).length;

  return !available && !unavailable;
};

export const getServicesUnavailable = (bookingServices, serviceToReserve) =>
  bookingServices.map((itemBooking) => serviceToReserve.find((item) => item.id === itemBooking.service_id));

export const getWeekDays = (t) => [
  t('WEEK_DAYS.SUNDAY'),
  t('WEEK_DAYS.MONDAY'),
  t('WEEK_DAYS.TUESDAY'),
  t('WEEK_DAYS.WEDNESDAY'),
  t('WEEK_DAYS.THURSDAY'),
  t('WEEK_DAYS.FRIDAY'),
  t('WEEK_DAYS.SATURDAY'),
  t('WEEK_DAYS.SUNDAY'),
];

export const generateInternalId = (id) => `${id}-${Math.random().toString(36).slice(2, 10)}`;

export const addInternalId = (service) => {
  if (service.bundled) {
    const bundleWithInternalId = service.bundle.map((bundleService) => ({
      ...bundleService,
      internalId: generateInternalId(bundleService.id),
    }));

    return { ...service, bundle: bundleWithInternalId, internalId: generateInternalId(service.id) };
  }
  return { ...service, internalId: generateInternalId(service.id) };
};

export const getCanChooseProviders = (showProviders: number) => {
  if (showProviders === 2) {
    return 'CANT';
  }
  if (showProviders === 1) {
    return 'MUST';
  }
  return 'CAN';
};

export const showSeeAllHoursAvailable = (
  reservations: ReservationStateProps,
  providersByLocation,
  isPlanSolo: boolean,
  canChooseProvider?: string,
) => {
  if (canChooseProvider === 'MUST') {
    return false;
  }
  if (reservations.step === 'dateSelection') {
    if (
      isPlanSolo === false &&
      !reservations.singleProvider &&
      (reservations.bookingMode === 'consecutive' || reservations.serviceBeingReserved?.bundled)
    ) {
      if (Object.values(reservations.servicesWithProviders).some((provider) => provider.providerId !== 0)) {
        return true;
      }
    }
    if (
      providersByLocation?.length > 1 &&
      !reservations.serviceBeingReserved?.hasSession &&
      reservations.providerPreSelected &&
      reservations.bookingMode !== 'consecutive'
    ) {
      return true;
    }
  }
  return false;
};

export const deleteServiceFromBooking = ({ dispatch, length, handleClose, service }) => {
  if (length === 1 && handleClose) {
    handleClose();
  }
  dispatch({ type: 'REMOVE_SERVICE', payload: service });
};

export const getNumberAndParse = (array, property: string) =>
  array
    .map((item: {}) => (typeof item[property] === 'string' ? 0 : item[property]))
    .reduce((prev: number, curr: number) => prev + curr, 0);

export const getRandomNumber = (...args) =>
  args.length <= 2
    ? Math.floor(Math.random() * (args[1] - args[0] + 1)) + args[0]
    : args[Math.floor(Math.random() * args.length)];

export const getTailwindWidth = (number) => {
  if (number === 1) {
    return 'w-1/12';
  }
  if (number === 2) {
    return 'w-2/12';
  }
  if (number === 3) {
    return 'w-3/12';
  }
  if (number === 4) {
    return 'w-4/12';
  }
  if (number === 5) {
    return 'w-5/12';
  }
  if (number === 6) {
    return 'w-6/12';
  }
  if (number === 7) {
    return 'w-7/12';
  }
  if (number === 8) {
    return 'w-8/12';
  }
  if (number === 9) {
    return 'w-9/12';
  }
  if (number === 10) {
    return 'w-10/12';
  }
  if (number === 11) {
    return 'w-11/12';
  }
  if (number === 12) {
    return 'w-full';
  }

  return undefined;
};

export const encrypt = (id) => {
  try {
    const multiplierId = id * 30;

    return encode(String(multiplierId));
  } catch (error) {
    return 0;
  }
};
