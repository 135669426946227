import { FC, useEffect, useRef, useState } from 'react';
import { MobileArrowLeft, MobileArrowRight } from '@agendapro/emerald-icons';

import { SliderContainerProps } from './types';
import * as St from './SliderContainer.styles';

export const SliderContainer: FC<SliderContainerProps> = ({
  children,
  scrollOffset = 80,
  className,
  isCategory = false,
}) => {
  const sliderContainer = useRef<HTMLDivElement | null>(null);
  const [isContentTruncated, setIsContentTruncated] = useState(true);
  const [isShowPrevBtn, setIsShowPrevBtn] = useState(false);

  useEffect(() => {
    const containerRef = sliderContainer.current;

    if (containerRef) {
      const timeout = setTimeout(() => {
        const { scrollWidth, offsetWidth } = containerRef;

        setIsContentTruncated((_) => offsetWidth !== scrollWidth);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, []);

  return (
    <St.Wrapper className={className}>
      {isShowPrevBtn && !isCategory && (
        <St.ArrowButton
          onClick={() => {
            const currentContainer = document.getElementById('slider');

            currentContainer!.scrollLeft -= scrollOffset;
            setIsShowPrevBtn(() => currentContainer!.scrollLeft !== 0);
            setIsContentTruncated(
              (_) => currentContainer!.offsetWidth + currentContainer!.scrollLeft !== currentContainer!.scrollWidth,
            );
          }}
        >
          <MobileArrowLeft size={25} />
        </St.ArrowButton>
      )}
      <St.ScrollbarWrapper id="slider" ref={sliderContainer}>
        {children}
      </St.ScrollbarWrapper>
      {isContentTruncated && !isCategory && (
        <St.ArrowButton
          onClick={() => {
            const currentContainer = document.getElementById('slider');

            currentContainer!.scrollLeft += scrollOffset;
            setIsShowPrevBtn(() => true);
            setIsContentTruncated(
              (_) => currentContainer!.offsetWidth + currentContainer!.scrollLeft !== currentContainer!.scrollWidth,
            );
          }}
        >
          <MobileArrowRight size={25} />
        </St.ArrowButton>
      )}
    </St.Wrapper>
  );
};
