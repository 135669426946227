/* eslint-disable no-console */
import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { Filters, Minus, Plus } from '@agendapro/emerald-icons';

import cloneDeep from 'lodash/cloneDeep';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

import { ReservationsProvider, reservationsInitialState } from '@/context/ReservationsContext';
import useCheckRefScrolled from '@/hooks/useCheckRefScrolled';
import { useShowComponent } from '@/hooks/showComponent';
import usePageContext from '@/hooks/usePageContext';
import useAuthContext from '@/hooks/useAuthContext';
import { AlertModal } from '@/UI/Molecules/AlertModal';
import { CardService } from '@/UI/Molecules/CardService';
import { devices } from '@/UI/Styles/Queries';
import { ErrorModal } from '@/UI/Molecules/ErrorModal';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import SearchBar from '@/UI/Molecules/SearchBar';
import { validateIfTextContainsStr } from '@/UI/Utils/utils';

import { addInternalId, getCanChooseProviders } from '@/utils';
import { NoResultsMessage } from '../NoResultsMessage';
import { SliderFilterButtons } from '../SliderFilterButtons';
import { MobileCategories } from '../MobileCategories';
import { ServicesOfferedProps } from './ServicesOffered.types';

import { useCompanyBookingInfo } from '@/services/company';
import { bundledServiceProps, Service, ServiceModel } from '@/services/services';
import ServiceSkeletons from './ServiceSkeletons';
import FilterSkeletons from './FilterSkeletons';
import { SliderAvatarEmployees } from '@/entries/BranchOffice/components/ServicesByLocation/SliderAvatarEmployees';

import * as St from './ServicesOffered.styles';

const ReservationModal = dynamic(() => import('../ReservationModal/ReservationModal'));

const SalfaDialog = dynamic(() => import('../SalfaDialog'));

const CartDialog = dynamic(() => import('../CartDialog'));

const ServicesSelectedDrawer = dynamic(() => import('../ServicesSelectedDrawer'));

const ExistentClientModal = dynamic(() => import('@/UI/Molecules/ExistentClientModal/ExistentClientModal'));

const getServiceType = (service: ServiceModel) => {
  if (service.hasSession) {
    return 'SESSIONS';
  }
  if (service.bundled) {
    return 'BUNDLE';
  }
  // if (service.classService) {
  //   return 'CLASS';
  // }
  return 'SERVICE';
};

export const ServicesOffered: FC<ServicesOfferedProps> = ({
  dispatch,
  isLoading,
  serviceProvider,
  services,
  singleProvider,
  selected,
  setSelected,
  setWiggle,
  isCartShown,
  hideCart,
  showCart,
}) => {
  const router = useRouter();
  const [openSalfa, setOpenSalfa] = useState(false);
  const [search, setSearch] = useState('');
  const { auth } = useAuthContext();
  const [reservationModalState, setReservationModalState] = useState(reservationsInitialState);
  const { companyOverview, companySlug, isSalfa } = usePageContext();
  const { data: companyBookingInfo, isLoading: isBookingInfoLoading, isError } = useCompanyBookingInfo(companySlug);
  const filterContainer = useRef<HTMLDivElement>(null);
  const scrolled = useCheckRefScrolled(filterContainer);
  const alertDismissModal = useShowComponent();
  const { hide: hideCheckExisting, show: showCheckExisting, isShown: isShownCheckExisting } = useShowComponent();
  const { hide: hideAvancedFilter, show: showAdvancedFilter, isShown: isAdvancedFilterShown } = useShowComponent();
  const {
    hide: hideReservationModal,
    show: showReservationModal,
    isShown: isReservationModalShown,
  } = useShowComponent();
  const { hide: hideSelectionError, show: showSelectionError, isShown: isShownSelectionError } = useShowComponent();
  const { locationId } = router.query as { locationId: string };
  const { palette } = useTheme();
  const { t } = useTranslation();
  const isXLarge = useMediaQuery({ query: devices.XLarge });

  const [showServicesSelectedDrawer, setShowServicesSelectedDrawer] = useState(false);

  const handleAddService = (service: ServiceModel) => {
    const selectionType = selected[0] ? getServiceType(selected[0]) : '';
    const serviceType = getServiceType(service);
    const isServiceSelected = selected.some((selectedService) => selectedService.id === service.id);
    const selectionHasCharly = selected.some((service) => !!service.charlyDiscount);

    if (isServiceSelected && ['BUNDLE', 'SESSIONS'].includes(selectionType)) {
      return setSelected(selected.filter((selectedService) => selectedService.id !== service.id));
    }
    if (selectionHasCharly || (selectionType === 'SERVICE' && !!service.charlyDiscount)) {
      return showSelectionError();
    }
    if (selected.length && (serviceType !== selectionType || ['BUNDLE', 'SESSIONS'].includes(selectionType))) {
      return showSelectionError();
    }
    document.querySelector('.cart-sticky-content')?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    setWiggle(true);
    setTimeout(() => setWiggle(false), 1000);
    setSelected([...selected, addInternalId(service)]);

    return null;
  };

  const noResults = services.dataFiltered
    ? services.dataFiltered.flatMap((category: Service) => category.services).length === 0
    : true;

  const getInitialStep = (selected: ServiceModel[]) => {
    const isSession = !!selected[0]?.hasSession;
    const isBundle = selected[0]?.bundled;
    const canChooseProvider = companyBookingInfo?.showProviders !== 2;

    if ((isSession || isBundle) && !serviceProvider && canChooseProvider) {
      return 'providerSelection';
    }

    if (selected.length === 1) {
      return 'dateSelection';
    }

    return 'modeSelection';
  };

  const getServicesWithProviders = () => {
    const servicesReducer = (
      services: Array<ServiceModel | bundledServiceProps>,
      providerId: number,
      publicName: string,
    ) => services.reduce((acc, curr) => ({ ...acc, [curr.internalId as string]: { providerId, publicName } }), {});

    if (companyBookingInfo?.showProviders === 1 && !serviceProvider) {
      return {};
    }
    if (selected[0]?.bundled && serviceProvider) {
      return servicesReducer(selected[0].bundle, serviceProvider.id, serviceProvider.publicName);
    }
    if (serviceProvider) {
      return servicesReducer(selected, serviceProvider.id, serviceProvider.publicName);
    }
    if (selected[0]?.bundled) {
      return servicesReducer(selected[0].bundle, 0, t('FIRST_PROVIDER_AVAILABLE'));
    }
    if (companyBookingInfo?.showProviders === 2) {
      return servicesReducer(selected, 0, t('FIRST_PROVIDER_AVAILABLE'));
    }
    return {};
  };

  const handleReservationModal = (selected: ServiceModel[]) => {
    const serviceBeingReserved = selected.length > 1 ? null : selected[0];
    const selectedCopy = cloneDeep(selected);
    const initialStep = getInitialStep(selected);

    if (companyBookingInfo) {
      setReservationModalState((st) => ({
        ...st,
        handleClose: () => {
          alertDismissModal.show();
          if (isSalfa) {
            setSelected([]);
          }
        },
        handleCloseNow: () => {
          hideReservationModal();
          if (isSalfa) {
            setSelected([]);
          }
        },

        companyBookingInfo,
        client: {
          firstName: st.client.firstName || auth.userData?.firstName,
          lastName: st.client.lastName || auth.userData?.lastName,
          email: st.client.email || auth.userData?.email,
          phone: st.client.phone || auth.userData?.phone,
          identificationNumber: st.client.identificationNumber,
        },
        providerPreSelected: serviceProvider || null,
        servicesToReserve: selectedCopy,
        servicesToReserveUnmuted: selectedCopy,
        locationId: +locationId,
        servicesWithProviders: getServicesWithProviders(),
        servicesIds: selected[0].bundled ? [] : selected.map((service) => service.internalId!),
        step: initialStep,
        bookingMode: null,
        serviceBeingReserved,
        isSession: !!selected[0].hasSession,
        currentIndex: selected.findIndex((service) => service.id === serviceBeingReserved?.id),
        canChooseProvider: getCanChooseProviders(companyBookingInfo?.showProviders),
        singleProvider,
        setSelected,
      }));
      return companyBookingInfo.clientExclusive && companyBookingInfo.clientExclusiveBy !== null
        ? showCheckExisting()
        : showReservationModal();
    }
    return null;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const servicesId = urlParams.get('services_id');
    const bundleServicesId = urlParams.get('bundle_services_id');
    const creativeSource = urlParams.get('creative_source');
    const flatServices = services.data?.flatMap((item) => item.services);

    const bundleIdSelection = bundleServicesId?.split(',') || [];
    const servicesIdSelection = servicesId?.split(',') || [];

    const selectedServices = servicesId
      ? flatServices.filter(
          (flatService) => servicesIdSelection.includes(flatService.id.toString()) && !flatService.bundled,
        )
      : flatServices.filter(
          (flatService) => bundleIdSelection.includes(flatService.id.toString()) && flatService.bundled,
        );
    const selectedServicesWithInternalId = selectedServices?.map((service) => addInternalId(service));

    if (selectedServices.length > 0 && companyBookingInfo) {
      setSelected(selectedServicesWithInternalId);
      if (creativeSource === 'marketplace') {
        handleReservationModal(selectedServicesWithInternalId);
      } else {
        showCart();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services.data, companyBookingInfo]);

  useEffect(() => {
    const showServicesSelectedDrawer = selected.length > 0 && !isReservationModalShown && !isCartShown;

    setShowServicesSelectedDrawer(showServicesSelectedDrawer);
  }, [selected, isReservationModalShown, isCartShown]);

  const onSearch = (value) => {
    setSearch(value);
    const filterItems = ['name', 'description'];
    const listOfServices = services.data;
    const matching = listOfServices.map((service) => ({
      ...service,
      services: service.services.filter(
        (listToMatchText) =>
          validateIfTextContainsStr(listToMatchText[filterItems[0]], value) ||
          validateIfTextContainsStr(listToMatchText[filterItems[1]], value),
      ),
    }));

    if (value.length > 4) {
      dispatch({ type: 'SEARCH', payload: matching });
    }
    if (value === '') {
      dispatch({ type: 'SEARCH_RESET' });
    }
  };

  const shouldShowFiltering = !!(
    services.data?.length === 1 ||
    (services.data.length <= 3 && services.data.every((category) => category.services.length <= 2))
  );

  const getFiltering = (isRef) =>
    !isReservationModalShown && !shouldShowFiltering ? (
      <St.FilterContainer
        ref={isRef ? filterContainer : null}
        scrolled={scrolled && !isReservationModalShown}
        isRef={isRef}
      >
        {!isXLarge && companyBookingInfo?.showProviders !== 2 && companyOverview?.isPlanSolo === false && isRef && (
          <St.Title weight="bold">{t('SELECT_THE_SERVICES_YOU_ARE_LOOKING_FOR')}</St.Title>
        )}
        <SearchBar value={search} onChange={onSearch} placeholder={`${t('FIND_THE_SERVICE_YOU_ARE_LOOKING')}`} />
        <SliderFilterButtons services={services} dispatch={dispatch} />
        {services.listOfCategories.length > 3 && !isXLarge && (
          <St.AdvancedFilterContainer onClick={showAdvancedFilter}>
            <Filters size={20} color={palette.grays200} />
            <Paragraph weight="light" color={palette.grays200}>{`${t('SEE_ALL_CATEGORIES')}`}</Paragraph>
          </St.AdvancedFilterContainer>
        )}
      </St.FilterContainer>
    ) : null;

  if (isLoading || isBookingInfoLoading) {
    return (
      <St.SkeletonContainer>
        <FilterSkeletons />
        <St.ServicesContainer>
          <ServiceSkeletons />
        </St.ServicesContainer>
      </St.SkeletonContainer>
    );
  }
  return (
    <>
      {isShownSelectionError && (
        <ErrorModal
          hide={hideSelectionError}
          service={selected && selected[0].name}
          isCharly={selected.some((service) => !!service.charlyDiscount)}
        />
      )}
      {companyBookingInfo?.showProviders !== 2 && <SliderAvatarEmployees hasSelectedServices={!!selected.length} />}
      {(isXLarge || shouldShowFiltering) &&
        companyBookingInfo?.showProviders !== 2 &&
        companyOverview?.isPlanSolo === false && (
          <St.Title weight="bold">{t('SELECT_THE_SERVICES_YOU_ARE_LOOKING_FOR')}</St.Title>
        )}
      <St.Wrapper
        showSearchBar={shouldShowFiltering}
        isB2C={companyOverview?.isSolo || companyOverview?.isPlanSolo}
        showsFiltering={shouldShowFiltering}
      >
        <>
          {getFiltering(true)}
          {getFiltering(false)}
          {!!services.dataFiltered?.flatMap((category: Service) => category.services).length && (
            <St.ServicesContainer showSearchBar={shouldShowFiltering}>
              {services.dataFiltered
                ?.filter((data) => data.services.length > 0)
                .map((data) => (
                  <div key={data.id}>
                    <St.CategoryContainer
                      data-cy="category"
                      data-testid="categoryMainContainer"
                      onClick={() => dispatch({ type: 'EXPAND_CATEGORY', payload: data.category })}
                    >
                      <Paragraph size="subHeadline">{data.category}</Paragraph>
                      {services.expandedCategories?.includes(data.category) ? <Minus size={20} /> : <Plus size={20} />}
                    </St.CategoryContainer>
                    {services.expandedCategories?.includes(data.category) && (
                      <St.ServiceContent>
                        {data.services.map((service) => (
                          <St.ServiceContainer key={`${service.name}-${service.id}`} className="cardServiceItem">
                            <CardService
                              handleAddService={handleAddService}
                              handleRemoveService={() => {
                                setSelected(
                                  selected.filter(
                                    (_, index) =>
                                      index !==
                                      selected.findIndex((selectedService) => selectedService.id === service.id),
                                  ),
                                );
                              }}
                              selected={selected}
                              service={service}
                              setOpenSalfa={setOpenSalfa}
                              setSelected={setSelected}
                              isSalfa={isSalfa}
                            />
                          </St.ServiceContainer>
                        ))}
                      </St.ServiceContent>
                    )}
                  </div>
                ))}
            </St.ServicesContainer>
          )}
          {noResults && !isLoading && (
            <St.EmptyContainer>
              <NoResultsMessage message={`${t('NO_RESULTS_TO_SHOW')}`} />
            </St.EmptyContainer>
          )}
          {isAdvancedFilterShown && (
            <MobileCategories services={services} dispatch={dispatch} handleClose={hideAvancedFilter} />
          )}
        </>
        {isShownCheckExisting && (
          <ExistentClientModal
            setReservationsInitialState={setReservationModalState}
            handleClose={hideCheckExisting}
            showReservationModal={showReservationModal}
            identificadorType={companyBookingInfo?.clientExclusiveBy || 0}
          />
        )}
        {isReservationModalShown && (
          <ReservationsProvider initialState={reservationModalState}>
            <ReservationModal />
          </ReservationsProvider>
        )}
        {openSalfa && (
          <SalfaDialog
            open={openSalfa}
            onDismiss={() => {
              setSelected([]);
              setOpenSalfa(false);
            }}
            onAccept={({ entity, client }) => {
              setReservationModalState((state) => ({
                ...state,
                entity,
                client,
              }));
              handleReservationModal(selected);
              setOpenSalfa(false);
            }}
          />
        )}
        {alertDismissModal.isShown && (
          <St.AlertModalContainer>
            <AlertModal
              title={t('SCHEDULING.TITLE')}
              showCancelButton
              titleSize="sectionHeadline"
              cancelButtonText={t('DISCARD')}
              confirmButtonText={t('SCHEDULING.CONTINUE')}
              description={t('SCHEDULING.DESCRIPTION')}
              onConfirm={() => {
                alertDismissModal.hide();
              }}
              onCancel={() => {
                alertDismissModal.hide();
                hideReservationModal();
                setSelected([]);
              }}
            />
          </St.AlertModalContainer>
        )}
      </St.Wrapper>
      {showServicesSelectedDrawer && (
        <ServicesSelectedDrawer
          selectedServices={selected}
          handleReservationModal={handleReservationModal}
          setShowServicesSelectedDrawer={setShowServicesSelectedDrawer}
        />
      )}
      {isCartShown && (
        <CartDialog
          services={selected}
          hide={hideCart}
          isShown={isCartShown}
          setSelected={setSelected}
          handleReservationModal={handleReservationModal}
        />
      )}
    </>
  );
};
