import styled from 'styled-components';
import { devices } from '@/UI/Styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.brandTheme.brand100};
  background-color: ${({ theme }) => theme.brandTheme.brand50};
  border-radius: 50%;
  height: 38px;
  width: 38px;
  min-height: 38px;
  min-width: 38px;
  p {
    color: ${({ theme }) => theme.brandTheme.brand100};
  }
  @media ${devices.XLarge} {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
  }
`;
