import styled from 'styled-components';

import { devices } from '@/UI/Styles/Queries';
import { applyBoxShadowSm, applyEllipsisByNumOfLines } from '@/styles/mixins';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const MainWrapper = styled.div`
  position: relative;
  padding: 8px 15px;
  ${applyBoxShadowSm()};
`;

export const Container = styled.nav`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.white};

  .userButton {
    cursor: pointer;
    display: flex;
    align-items: center;

    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }

    .arrowBottom {
      path {
        fill: ${({ theme }) => theme.palette.grays100};
      }
    }
  }

  .companyConfigContainer {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.grays50};
    column-gap: 16px;
    .logoButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 0;
    }
    .logoButton,
    img {
      cursor: pointer;
      width: 55px;
      height: 55px;

      @media ${devices['no-mobile']} {
        width: 50px;
        height: 50px;
      }
    }

    .siteName {
      color: ${({ theme }) => theme.palette.grays100};
      ${applyEllipsisByNumOfLines()}
    }
  }

  .navLinksContainer {
    display: none;
    button {
      border: none;
    }
    @media ${devices['no-mobile']} {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.palette.grays80};

      i {
        &:first-of-type {
          color: ${({ theme }) => theme.brandTheme.brand100};
          margin-right: 5px;
        }

        &:last-of-type {
          cursor: pointer;
          font-size: 15px;
          margin-left: 12px;
        }
      }

      .separator {
        border-right: 1px solid ${({ theme }) => theme.palette.grays80};
        margin: 0 23px;
        height: 10px;
      }

      p:last-child {
        cursor: pointer;
      }

      .navLink {
        margin-bottom: 2px;
        transition: box-shadow 200ms ease-in-out;

        &:hover {
          box-shadow: 0px 2px 0px 0px ${({ theme }) => theme.brandTheme.brand100};
        }
      }
    }
  }

  .openSideBtn {
    display: block;
    color: ${({ theme }) => theme.brandTheme.brand100};
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }

    @media ${devices['no-mobile']} {
      display: none;
    }
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media ${devices['no-mobile']} {
    flex-direction: row;
    align-items: center;
  }
`;

export const LocationName = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.grays80};
  margin-left: 0%;
  margin-right: auto;

  @media ${devices['no-mobile']} {
    position: relative;
    top: -2px;
    margin-right: 5px;
  }
`;

export const StyledSelectText = styled(Paragraph)`
  font-size: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.brandTheme.brand100};
  text-decoration: underline;
  position: relative;
  top: -2px;
`;

export const MobileSelectContainer = styled.div`
  button {
    border: none;
  }
  @media ${devices['no-mobile']} {
    display: none;
  }
`;
