import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: 999;
  overflow-y: auto;
`;

export const Content = styled.div`
  background-color: #ffffff;
  justify-content: center;
  align-content: center;
  margin: auto;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
`;
export const Header = styled.div`
  padding: 20px;
  box-shadow: 1px 3px 10px -4px #d2d2d2;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Button = styled.button`
  border: none;
  background: none;
  background-color: none;
`;
