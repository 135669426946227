import Image from 'next/image';
import styled from 'styled-components';
import { applyEllipsisByNumOfLines, applyFlexAndCenter, devices } from '../../Styles';
import { InitialsAvatar } from '../InitialsAvatar';

export const Wrapper = styled.div`
  ${applyFlexAndCenter}
  flex-direction: column;
  width: 100%;
  @media ${devices.Large} {
    width: 80px;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.white};
  margin-bottom: 16px;
  cursor: pointer;
  transition: 300ms ease-in-out;
  &:hover {
    border: 2px solid ${({ theme }) => theme.brandTheme.brand100};
  }
  @media ${devices['no-mobile']} {
    width: 59px;
    height: 59px;
  }
`;

export const CustomInitialsAvatar = styled(InitialsAvatar)`
  min-width: 52px;
  min-height: 52px;
  width: 52px;
  height: 52px;
  @media ${devices['no-mobile']} {
    min-width: 55px;
    min-height: 55px;
    width: 55px;
    height: 55px;
  }
`;

export const NameContainer = styled.div`
  p {
    word-break: break-word;
    text-align: center;
    color: ${({ theme }) => theme.palette.grays100};
    ${applyEllipsisByNumOfLines(2)}
  }
`;

export const AvatarImg = styled(Image)`
  border-radius: 50%;
`;
