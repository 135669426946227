import styled, { css } from 'styled-components';
import { applyBoxShadowSm } from '@/UI/Styles';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .mainText {
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray80')};
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  }
  .clearFilers {
    text-align: end;
    color: ${({ theme }) => theme.brandTheme.brand100};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Header = styled.div`
  padding: 21px;
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  ${applyBoxShadowSm}
`;

export const Content = styled.div`
  height: 100%;
  padding: 0px 21px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 143px);

  overflow-y: scroll;
  > button:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  }
`;

export const Category = styled.button<{ isSelected?: boolean }>`
  padding: 4px 0px;
  p {
    padding: 7px 5px;
    transition: 200ms ease-in-out;
    ${({ isSelected, theme }) =>
      isSelected &&
      css`
        border-radius: 8px;
        background-color: ${theme.migrateColors('background.btnTertiary', 'background')};
      `}
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[16]};
  box-shadow: 0px -4px 4px 0px #0000000a;
  .applyBtn {
    background-color: ${({ theme }) => theme.brandTheme.brand100};
    height: 36px;
    padding: ${({ theme }) => theme.spacing[8]};
    border-radius: 6px;

    p {
      font-size: 14px;
    }
  }
`;
