import { FC } from 'react';
import dayjs from 'dayjs';

import { Button } from '@agendapro/emerald/v2';
import { useReservation } from '@/context/ReservationsContext';
import { formatAMPMHour } from '@/utils';
import * as St from './HourMapper.styles';
import { Hour } from '@/services/serviceProviders';

export const HourMapper: FC<{ hours: Hour[]; handleNext?: () => void }> = ({ hours, handleNext }) => {
  const { reservations, dispatch } = useReservation();

  const {
    serviceBeingReserved,
    servicesToReserve,
    providerPreSelected,
    bookingMode,
    currentIndex,
    servicesToReserveUnmuted,
    skipStep,
  } = reservations;
  const { charlyPromotionStartDate, charlyPromotionEndDate, payingPriceCharly, charlyDiscount } =
    servicesToReserve[currentIndex];

  const hourSelect = (hour: Hour) => {
    const getBooking = () => {
      if (serviceBeingReserved?.bundled) {
        const newBundle = hour.bookings.map((booking) => {
          const service = servicesToReserve[0].bundle.find((b) => b.id === booking.service);

          return { ...service, ...booking };
        });

        return { bundle: newBundle };
      }
      if (bookingMode === 'consecutive') {
        const serviceBooking = hour.bookings.map((service, index) => {
          const {
            charlyPromotionStartDate: serviceCharlyPromotionStartDate,
            charlyPromotionEndDate: serviceCharlyPromotionEndDate,
            payingPriceCharly: servicePayingPriceCharly,
            charlyDiscount: serviceCharlyDiscount,
          } = servicesToReserve[index];
          const hasServiceCharlyDiscount = !!serviceCharlyPromotionStartDate && !!serviceCharlyPromotionEndDate;

          return {
            ...service,
            ...(hasServiceCharlyDiscount &&
            dayjs(service.start).isBetween(serviceCharlyPromotionStartDate, serviceCharlyPromotionEndDate, 'day', '[]')
              ? {
                  discount: serviceCharlyDiscount,
                  price: servicePayingPriceCharly || 0,
                  emailTrackNumber: servicesToReserve[index]?.emailTrackNumber,
                }
              : {}),
          };
        });

        servicesToReserve.forEach((service, index) => {
          const serviceUpdated = serviceBooking[index];

          dispatch({
            type: 'ADD_DATA_TO_SERVICE_BY_INDEX',
            payload: {
              index,
              data: {
                ...service,
                ...serviceUpdated,
              },
            },
          });
        });
        return {};
      }

      const hasCharlyDiscount = !!charlyPromotionStartDate && !!charlyPromotionEndDate;

      if (providerPreSelected) {
        return {
          ...hour.bookings[0],
          ...(hasCharlyDiscount &&
          dayjs(hour.bookings[0].start).isBetween(charlyPromotionStartDate, charlyPromotionEndDate, 'day', '[]')
            ? {
                discount: charlyDiscount,
                payingPrice: payingPriceCharly,
                price: payingPriceCharly,
                emailTrackNumber: servicesToReserve[currentIndex].emailTrackNumber,
              }
            : { emailTrackNumber: null }),
        };
      }
      return {
        ...hour.bookings[0],
        ...(hasCharlyDiscount &&
        dayjs(hour.bookings[0].start).isBetween(charlyPromotionStartDate, charlyPromotionEndDate, 'day', '[]')
          ? {
              discount: charlyDiscount,
              price: payingPriceCharly,
              emailTrackNumber: servicesToReserve[currentIndex]?.emailTrackNumber,
            }
          : {
              emailTrackNumber: null,
              discount: servicesToReserveUnmuted[currentIndex]?.discount,
              price: servicesToReserveUnmuted[currentIndex]?.payingPrice,
            }),
      };
    };

    dispatch({ type: 'SET_HOUR_SELECTED', payload: hour.startBlock });
    dispatch({
      type: 'ADD_DATA_TO_CURRENT_SERVICE',
      payload: {
        hourSelected: hour.startBlock,
        hourEnd: hour.endBlock,
        ...getBooking(),
      },
    });
    if (skipStep && handleNext && !reservations?.isSession) {
      // skip on schedule click is not done in sessions since it's incompatible with the sessions flow.
      handleNext();
    }
  };

  return (
    <St.Wrapper>
      {hours.map((hour) => (
        <St.ButtonContainer key={hour.hour} isSelected={reservations.hourSelected === hour.startBlock}>
          <Button key={hour.hour} onClick={() => hourSelect(hour)} data-cy="hour_select">
            {formatAMPMHour(hour.startBlock)}
          </Button>
        </St.ButtonContainer>
      ))}
    </St.Wrapper>
  );
};
