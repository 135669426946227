import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@agendapro/emerald/v2';
import { TitledModal, Paragraph } from '@/UI';

import * as St from './MobileCategories.styles';
import { MobileCategoriesProps } from './MobileCategories.types';

export const MobileCategories: FC<MobileCategoriesProps> = ({ services, dispatch, handleClose }) => {
  const { t } = useTranslation();

  return (
    <TitledModal title={`${t('ADVANCED_FILTER')}`} handleClose={handleClose}>
      <St.Wrapper>
        <St.Header>
          <Paragraph className="mainText" weight="light">{`${t('SELECT_ONE_OR_MORE_CATEGORIES')}`}</Paragraph>
        </St.Header>
        <St.Content>
          {services.listOfCategories.map((category) => (
            <St.Category
              key={category.id}
              onClick={() => dispatch({ type: 'SELECT_MULTIPLE_CATEGORIES', payload: category.id })}
              isSelected={services.currentFilterIds.some((number) => number === category.id)}
            >
              <Paragraph weight="bold" size="subHeadline">
                {category.name}
              </Paragraph>
            </St.Category>
          ))}
        </St.Content>
        <St.ButtonContainer>
          <Paragraph className="clearFilers" weight="light" onClick={() => dispatch({ type: 'SEARCH_RESET' })}>{`${t(
            'CLEAR_FILTERS',
          )}`}</Paragraph>
          <Button className="applyBtn" onClick={handleClose}>{`${t('BUTTON_APPLY')}`}</Button>
        </St.ButtonContainer>
      </St.Wrapper>
    </TitledModal>
  );
};
