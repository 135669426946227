import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { SliderContainer, ButtonCategory } from '@/UI';
import { devices } from '@/UI/Styles';

import * as St from './SliderFilterButtons.styles';
import { SliderFilterButtonProps } from './types';

export const SliderFilterButtons: FC<SliderFilterButtonProps> = ({ services, dispatch }) => {
  const { listOfCategories, currentFilterIds } = services;
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  return (
    <St.Wrapper>
      {!!listOfCategories && (
        <>
          {isXLarge ? (
            <St.CategoryContainer>
              {listOfCategories.map(({ id, name }) => (
                <St.Category key={id}>
                  <ButtonCategory
                    label={name}
                    isCharly={id === 'DISCOUNT'}
                    onClick={() => dispatch({ type: 'SELECT_SINGLE_CATEGORY', payload: id })}
                    isSelected={currentFilterIds.some((categoryId) => categoryId === id)}
                  />
                </St.Category>
              ))}
            </St.CategoryContainer>
          ) : (
            <St.SliderWrapper>
              <St.SliderMobile>
                {listOfCategories.map(({ id, name }) => (
                  <ButtonCategory
                    key={id}
                    isCharly={id === 'DISCOUNT'}
                    label={name}
                    onClick={() => dispatch({ type: 'SELECT_SINGLE_CATEGORY', payload: id })}
                    isSelected={currentFilterIds.some((categoryId) => categoryId === id)}
                  />
                ))}
              </St.SliderMobile>
            </St.SliderWrapper>
          )}
        </>
      )}
    </St.Wrapper>
  );
};
