import { FC } from 'react';
import { Paragraph } from '../..';
import { addUpperCaseToName } from '../../Utils';
import * as St from './AvatarEmployee.styles';
import { AvatarEmployeeProps } from './types';

export const AvatarEmployee: FC<AvatarEmployeeProps> = ({ image, name }) => (
  <St.Wrapper>
    {image ? (
      <St.AvatarWrapper>
        <St.AvatarImg src={image} alt={name} width={55} height={55} quality={100} data-testid="avatarImage" />
      </St.AvatarWrapper>
    ) : (
      <St.AvatarWrapper>
        <St.CustomInitialsAvatar name={name} />
      </St.AvatarWrapper>
    )}
    <St.NameContainer>
      <Paragraph weight="light" size="small">
        {addUpperCaseToName(name)}
      </Paragraph>
    </St.NameContainer>
  </St.Wrapper>
);
