/* eslint-disable no-nested-ternary */
import Image from 'next/image';
import styled from 'styled-components';

import { devices } from '../../Styles';

type BannerLogoContainerProps = {
  banner?: string;
  collapsed?: boolean;
};

export const NextImage = styled(Image)`
  border-radius: 8px;
`;

export const Wrapper = styled.div<BannerLogoContainerProps>`
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
  justify-content: flex-start;
  padding: 22px 0px 18px 25px;
  position: relative;
  width: ${({ collapsed }) => (collapsed ? 'unset' : '100%')};
  @media ${devices.Medium} {
    padding: 31px 0px 37px 48px;
    display: flex;
    justify-content: flex-start;
  }
`;
export const Logo = styled.img<BannerLogoContainerProps>`
  z-index: 2;
  border-radius: 8px;
  height: 85px;
  width: 85px;
  background-color: ${({ theme }) => theme.palette.white};
  @media ${devices.Medium} {
    height: ${({ collapsed }) => (collapsed ? '118px' : '166px')};
    width: ${({ collapsed }) => (collapsed ? '118px' : '166px')};
  }
`;

export const BannerGradient = styled.div`
  background: ${({ theme }) => `linear-gradient(50deg, ${theme.brandTheme.brand50} 0%, #fff 100%)`};
  border-radius: 8px;
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;
