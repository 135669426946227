import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import * as Repo from './reservations.repo';
import * as Types from './reservations.types';

export const useExistentClient = () =>
  useMutation<Types.ExistentClientResponse, AxiosError, Types.ExistentClientRequest>(
    ({ clientId, companySlug, identificadorType }) =>
      Repo.checkExistingClient(clientId, companySlug, identificadorType),
  );
