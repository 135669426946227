import { API_V1, API_V2 } from '@/infra/http';

import * as Types from './company.types';

export const getCompanyOverview = async (website = '', locale = 'cl', locationId = '') =>
  API_V2.get<Types.CompanyOverviewResponse>(`/companies/overview`, { params: { website, locale, locationId } })
    .then((res) => res.data)
    .then((res) => ({ ...res, logo: res.logo || '/assets/aProDefaultLogo.png' }));

export const getCompanyBookingInfo = async (website = '', locale = 'cl') =>
  API_V2.get<Types.BookingInfoResponse>(`/companies/booking_info`, { params: { website, locale } }).then(
    (res) => res.data,
  );

export const getCompanyRRSS = (companyId?: number) =>
  API_V2.get<Types.RRSSResponse[]>('/social_networks', { params: { companyId } }).then((res) => res.data);

export const checkWebAddressCountries = (webAddress) =>
  API_V1.get<Types.CheckWebAddressCountriesResponse>('api/views/workflow/v2/check_web_address_countries', {
    params: { web_address: webAddress },
  }).then((res) => res.data.data);
